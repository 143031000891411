import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endponitConfig } from '../../../environments/endpoint';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReportsService {

    headers = new HttpHeaders().set('Authorization', JSON.parse(sessionStorage.getItem("Autherization")))

    constructor(private readonly http: HttpClient) { }

    getOrderDetailsbyInvoice(id) {
        return this.http.get(`${endponitConfig.MBB_ORDERS}getOrderDetails/${id}`, { headers: this.headers }).map((response: any) => response);
    }

    getProductTrackList(sku, statusId) {
        return this.http.get(`${endponitConfig.INVENTORY_ENDPOINT}getCountBasedOnFacility/${sku}/${statusId}`, { headers: this.headers }).map((response: any) => response);
    }

    getLocationWiseProductDetails(sku, statusId, facilityId) {
        return this.http.get(`${endponitConfig.INVENTORY_ENDPOINT}getItemsBasedOnSkuCode/${sku}/${statusId}/${facilityId}`, 
        { headers: this.headers }).map((response: any) => response);
    }

    getOrderDetailsbyInvoiceNo(id) {
        return this.http.get(`${endponitConfig.DISPATCH_ENDPONT}getByInvoiceNo/${id}`, { headers: this.headers }).map((response: any) => response);
    }
    getSKUImage(skuCode){
        return this.http.get(endponitConfig.INVENTORY_ENDPOINT +`getInventoryImage/${skuCode}`, { headers: this.headers })
        .map((response: any) => response);
    }
    viewAccessoriesCount(id,facId,status){
        return this.http.get(endponitConfig.INVENTORY_ENDPOINT +`viewAccessoriesInInventory/${id}/${facId}/${status}`, { headers: this.headers })
        .map((response: any) => response);
    }
    getImage(id){
        return this.http.get(endponitConfig.DISPATCH_ENDPONT +`getDispatchPaymentDocument/${id}`, { headers: this.headers })
        .map((response: any) => response);
    }

    getInventoryXlSheet(userId,facilityId,status){
        return this.http.get(endponitConfig.INVENTORY_ENDPOINT +`getAllQrForCSV/${facilityId}/${status}/${userId}`, { headers: this.headers })
        .map((response: any) => response);
    }

    documentsUpload(obj) {
        return this.http.put(endponitConfig.DISPATCH_ENDPONT + `updateDispatchPaymentDocuments/` + obj.dispatchId, obj, { headers: this.headers })
            .map((response: any) => response);
    }
    documentsUpload2(obj) {
        return this.http.post(endponitConfig.DISPATCH_ENDPONT + `addDispatchPaymentDocument`, obj, { headers: this.headers })
            .map((response: any) => response);
    }
    updatePaymentMode(dispatchId,paymentId) {
        return this.http.put(endponitConfig.DISPATCH_ENDPONT + `updatePaymentMode/${dispatchId}/${paymentId}` , { headers: this.headers })
            .map((response: any) => response);
    }
    private subject = new BehaviorSubject<any>(null);

    sendObject(event:any) {
        this.subject.next(event);
    }
 
    clearObject() {
        this.subject.next(null);
    }
 
    getObject(): Observable<any> {
        return this.subject.asObservable();
    }
    addHistory(history, barcode) {
        return this.http.post(endponitConfig.RENTALS_API + 'addHistory', { history, barcode }, { headers: this.headers })
            .map((response: any) => response);
    }
    getHistory(barcode) {
        return this.http.get(endponitConfig.RENTALS_API + `getAllHistory/${barcode}`, { headers: this.headers })
            .map((response: any) => response);
    }
    getAllPaymentModes() {
        return this.http.get(endponitConfig.DISPATCH_ENDPONT + 'paymentModesForUpdate', { headers: this.headers })
            .map((response: Response) => response);
    }
}


